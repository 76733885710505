import { useState } from 'react'
import { Button, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { HelpIcon } from '@enterprise-ui/icons'

const MMDescription = () => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        <EnterpriseIcon icon={HelpIcon} size="sm" style={{ marginRight: 5 }} />
        About
      </Button>

      <Modal
        headingText="About the Middle Mile General Merchandise Dashboard"
        isVisible={isVisible}
        onRefuse={() => setIsVisible(false)}
        size="normal"
      >
        <div className="hc-pa-normal">
          <Grid.Container direction="column">
            <Grid.Item>
              <div>
                The purpose of this dashboard is to give visibility to at risk,
                early, late and missed loads and ensure critical updates are
                provided by carriers to Target. Loads will fall in to the
                following categories if they meet the below criteria. To view
                the specific load details and provide updates to those loads,
                click on any of the numeric values and the loads will
                pre-populate in DLM load tracking.
              </div>

              <div className="hc-pt-md">
                Default view time period: Expected Delivery Date Range (Arrival
                Time) = 8AM day prior - 7:59am current day
              </div>
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Total Loads</div>
              </strong>
              Total loads with an Expected delivery date rage of 8AM day prior –
              7:59 current day
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">On-Time Loads</div>
              </strong>
              Loads with no alert status
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Defect Loads</div>
              </strong>
              Total of At Risk, Early, Late and Missed loads
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  Defect Loads Missing Reason Codes
                </div>
              </strong>
              Total of At Risk, Early, Late and Missed loads with no delivery
              defect reason code entered in DLM Tracking
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">At Risk</div>
              </strong>
              arrival time (expected delivery) has passed, and no EDI 214 X1
              (arrived at delivery) timestamp has been received
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Early</div>
              </strong>
              EDI 214 X1 (arrived at delivery) event timestamp is 1 or more
              hours prior to arrival time for live unloads only
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Late</div>
              </strong>
              EDI 214 X1 (arrived at delivery) event timestamp is after arrival
              date/time (expected delivery)
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Missed</div>
              </strong>
              EDI 214 X1 (arrived at delivery) timestamp is 3 or more hours past
              arrival time (expected delivery)
            </Grid.Item>

            <Grid.Item>
              <Grid.Container justify="flex-end">
                <Grid.Item>
                  <Button onClick={() => setIsVisible(false)}>Close</Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}

export default MMDescription
