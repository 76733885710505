import { useState } from 'react'
import { Button, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { HelpIcon } from '@enterprise-ui/icons'

const FMDescription = () => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        <EnterpriseIcon icon={HelpIcon} size="sm" style={{ marginRight: 5 }} />
        About
      </Button>
      <Modal
        headingText="About the Domestic First Mile Outstanding Load Dashboard"
        isVisible={isVisible}
        onRefuse={() => setIsVisible(false)}
        size="normal"
      >
        <div className="hc-pa-normal">
          <Grid.Container direction="column">
            <Grid.Item>
              <div>
                The purpose of this dashboard is to give visibility to at risk
                and late loads and ensure critical updates are provided by
                carriers to Target. Loads will fall in to the following
                categories if they meet the below criteria. To view the specific
                load details and provide updates to those loads, click on any of
                the numeric values and the loads will pre-populate in DLM load
                Tracking.
              </div>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  At Risk, Past Expected Pickup
                </div>
              </strong>
              <ul>
                <li>Expected pickup date is in the past</li>
                <li>EDI 214 AF status has not been received</li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  At Risk, Past Expected Pickup status requiring action
                </div>
              </strong>
              <ul>
                <li>Expected pickup date is in the past</li>
                <li>EDI 214 AF status has not been received</li>
                <li>
                  Estimated pickup date has not been entered or is in the past
                </li>
                <li>Pickup defect reason code has not been entered</li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  Late Pickup status requiring action
                </div>
              </strong>
              <ul>
                <li>EDI 214 AF timestamp was after the expected pickup date</li>
                <li>Pickup defect reason code has not been entered</li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  Total loads requiring action
                </div>
              </strong>
              <ul>
                <li>
                  Any loads that fall in to: At Risk, Past Expected Pickup
                  status requiring action OR Late Pickup status requiring action
                </li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <Grid.Container justify="flex-end">
                <Grid.Item>
                  <Button onClick={() => setIsVisible(false)}>Close</Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}

export default FMDescription
