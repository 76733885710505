import { Table } from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'

const ActionTableData = ({
  index,
  filters,
  isDefectCell,
  success,
  displayText,
  xs,
}) => {
  return (
    <Table.Data xs={xs} key={index}>
      <NavLink
        to=".."
        state={{
          filters: filters,
        }}
      >
        <div
          className={`${
            isDefectCell ? (success ? 'hc-clr-success' : 'hc-clr-error') : ''
          }`}
        >
          {displayText}
        </div>
      </NavLink>
    </Table.Data>
  )
}

export default ActionTableData
