import {
  Button,
  Card,
  Grid,
  Heading,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import MMDashboardDescription from './MMDashboardDescription'
import ActionTableData from '../common/ActionTableData'
import DashboardOverview from '../common/DashboardOverview'

const ORIGIN_VIEW = 'ORIGIN'
const SCAC_VIEW = 'SCAC'

const MMDashboard = ({
  loadSummary,
  subheader,
  formatStateFilters = (_filters) => {
    return {}
  },
}) => {
  const [onView, setOnView] = useState(ORIGIN_VIEW)
  const summaryLength =
    loadSummary?.dashboard_summaries?.length > 0
      ? loadSummary.dashboard_summaries[0]?.summary_cells.length
      : 0
  const xs = Math.floor(12 / (summaryLength + 1))

  const toggleOnView = () => {
    if (onView === SCAC_VIEW) {
      setOnView(ORIGIN_VIEW)
    } else if (onView === ORIGIN_VIEW) {
      setOnView(SCAC_VIEW)
    }
    mapLoadSummary(loadSummary)
  }

  const getTotalFiltersForCell = (summaries, displaySequence) => {
    const summariesForCell = summaries.flatMap((summary) =>
      summary.summary_cells?.filter(
        (cell) => cell.display_sequence === displaySequence,
      ),
    )
    const allFilters = summariesForCell.flatMap((summary) => summary.filters)
    const filterNames = [
      ...new Set(allFilters.map((filter) => filter?.filter_name)),
    ]
    return filterNames.map((filterName) => {
      return {
        filter_name: filterName,
        filter_values: [
          ...new Set(
            allFilters
              .filter((filter) => filter.filter_name === filterName)
              .flatMap((filter) => filter.filter_values),
          ),
        ],
      }
    })
  }

  const getTotalCountForCell = (summaries, displaySequence) => {
    const summariesForCell = summaries.flatMap((summary) =>
      summary.summary_cells?.filter(
        (cell) => cell.display_sequence === displaySequence,
      ),
    )
    return summariesForCell.reduce((acc, summary) => {
      acc += summary.load_count
      return acc
    }, 0)
  }

  const buildCells = (summary) => {
    const firstSummary = summary?.summaries[0]

    return Array.from({ length: summaryLength }, (_, i) => {
      const displaySequence = i + 1
      return {
        name: firstSummary?.summary_cells?.find(
          (cell) => cell.display_sequence === displaySequence,
        )?.display_name,
        total_count: getTotalCountForCell(summary?.summaries, displaySequence),
        total_filters: getTotalFiltersForCell(
          summary?.summaries,
          displaySequence,
        ),
      }
    })
  }

  let mappedLoadSummary
  const mapLoadSummary = (loadSummary) => {
    const dashboardSummaries = loadSummary?.dashboard_summaries
    const uniqueValues = (key) =>
      [
        ...new Set(dashboardSummaries?.map((details) => details[key])?.sort()),
      ].filter((value) => value !== 'All')

    const scacs = uniqueValues('scac')
    const origins = uniqueValues('origin')

    const mapSummaries = (key, value) =>
      dashboardSummaries
        .filter((summary) => summary[key] === value)
        .flatMap((summary) => ({
          name: summary[key === 'scac' ? 'origin' : 'scac'],
          summary_cells: summary.summary_cells,
        }))

    mappedLoadSummary = (onView === SCAC_VIEW ? scacs : origins).map(
      (value) => ({
        name: value,
        summaries: mapSummaries(
          onView === SCAC_VIEW ? 'scac' : 'origin',
          value,
        ),
      }),
    )

    return mappedLoadSummary
  }

  return (
    <Card className="hc-pa-expanded">
      <Grid.Container direction="column">
        <Grid.Item>
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading size={3}>
                Middle Mile General Merchandise Dashboard
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <MMDashboardDescription />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>{subheader}</Grid.Item>
        <DashboardOverview
          loadSummary={loadSummary}
          formatStateFilters={formatStateFilters}
        />
        {mapLoadSummary(loadSummary) && (
          <>
            <Grid.Item>
              <Grid.Container justify="flex-end">
                <Grid.Item>
                  <Button
                    onClick={() => toggleOnView()}
                    aria-label="Switch Table View Button"
                  >
                    Switch to {onView === SCAC_VIEW ? 'Origin' : 'SCAC'} View
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item>
              {mappedLoadSummary.map((summary, index) => {
                const cells = buildCells(summary)
                return (
                  <Card
                    className="hc-pa-expanded hc-mb-expanded"
                    key={`${summary?.name}-${index}`}
                  >
                    <Heading size={4}>{summary?.name}</Heading>
                    <Table name={`${summary?.name} Details`}>
                      <Table.Head>
                        <Table.Row key={summary?.name}>
                          <Table.Header xs={xs}>
                            {onView === 'SCAC' ? 'Origin ID' : 'SCAC'}
                          </Table.Header>
                          {cells.map((cell, index) => (
                            <Table.Header xs={xs} key={`${cell.name}-${index}`}>
                              {cell?.name}
                            </Table.Header>
                          ))}
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        <Table.Row key={`${summary?.name}-total`}>
                          <ActionTableData
                            index={1}
                            filters={formatStateFilters(
                              cells[7]?.total_filters,
                            )}
                            displayText={<strong>{'Total'}</strong>}
                            xs={xs}
                          />
                          {cells.map((cell, index) => (
                            <ActionTableData
                              index={index}
                              filters={formatStateFilters(cell?.filters)}
                              isDefectCell={index > 1}
                              success={cell?.total_count === 0}
                              displayText={<strong>{cell?.total_count}</strong>}
                              xs={xs}
                            />
                          ))}
                        </Table.Row>
                        {summary?.summaries?.map((detailsSummary) => {
                          const cells = Array.from(
                            { length: summaryLength },
                            (_, i) =>
                              detailsSummary?.summary_cells?.find(
                                (cell) => cell.display_sequence === i + 1,
                              ),
                          )
                          return (
                            <Table.Row key={detailsSummary?.name}>
                              <ActionTableData
                                index={1}
                                filters={formatStateFilters(cells[7]?.filters)}
                                displayText={
                                  <strong>{detailsSummary?.name}</strong>
                                }
                                xs={xs}
                              />
                              {cells.map((cell, index) => (
                                <ActionTableData
                                  index={index}
                                  filters={formatStateFilters(cell?.filters)}
                                  isDefectCell={index > 1}
                                  success={cell?.load_count === 0}
                                  displayText={cell?.load_count}
                                  xs={xs}
                                />
                              ))}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                  </Card>
                )
              })}
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </Card>
  )
}

export default MMDashboard
